<template>
  <div class="iss-grid-wrap mt-16">
    <grid
      ref="gridRef"
      :allow-selection="false"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :pagination="false"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1640, y: gridHeight }"
    >
      <template #packageMoney="{ record }">
        <text>{{
          record.packageMoney == 0
            ? record.packageMoney
            : (record.packageMoney / 100).toFixed(2)
        }}</text>
      </template>
      <template #enable="{ record }">
        <a-switch
            v-model:checked="record.enable"
            checked-children="启用"
            un-checked-children="禁用"
            @click="handleClickSwitch(record)"
        />
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
    <a-modal
      v-model:visible="visible"
      :title="!form.id ? '新增' : '编辑'"
      cancelText="取消"
      okText="确认"
      :width="600"
      centered
      @ok="handleOk"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        :model="form"
      >
        <a-form-item label="套餐ID" v-bind="validateInfos.packageCode">
          <a-input
              :disabled="form.id === '' ? false : true"
              v-model:value="form.packageCode"
              placeholder="请输入套餐ID"
          />
        </a-form-item>
        <a-form-item label="套餐名称" v-bind="validateInfos.packageName">
          <a-input
            v-model:value="form.packageName"
            placeholder="请输入套餐名称"
          />
        </a-form-item>
        <a-form-item
          label="金币数量"
          v-bind="validateInfos.goldNumber"
          class="flex-row"
        >
          <a-input
            v-model:value="form.goldNumber"
            placeholder="请输入金币数量"
          />
          <text class="row-uni">个</text>
        </a-form-item>
        <a-form-item
          label="金币价格"
          v-bind="validateInfos.packageMoney"
          class="flex-row"
        >
          <a-input v-model:value="form.packageMoney" placeholder="金币价格" />
          <text class="row-uni">元</text>
        </a-form-item>
        <a-form-item label="启用状态">
          <a-switch
            v-model:checked="form.enable"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-item>
        <a-form-item label="描述" v-bind="validateInfos.packageDesc">
          <a-textarea
            v-model:value="form.packageDesc"
            placeholder="请输入描述"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
  <script>
import { reactive, toRefs, ref, toRaw } from 'vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {Form, Switch, message, Modal} from 'ant-design-vue';
import configurationApi from '@/api/configuration.js';
export default {
  components: {
    Operation,
    Grid,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      search: {
        type: 1,
      },
      TagList: [],
      visible: false,
    });
    let form = reactive({
      id:'',
      packageName: '',
      goldNumber: '',
      packageMoney: '',
      enable: true,
      packageDesc: '',
      packageCode: '',
    });
    const required = { required: true, message: '不能为空' };
    const checkZero = (rule, value) => {
      let num = Number(value)
      if (value && !(!isNaN(num) && num > 0)) {
        return Promise.reject('输入值必须为大于0');
      }else if(num>9999){
        return Promise.reject('输入值必须为小于10000的整数');
      }
      return Promise.resolve()
    };
    const { resetFields, validate, validateInfos } = Form.useForm(form, {
      goldNumber: [required,  {
          validator: checkZero,
          trigger: ['blur', 'change'],
        },],
      packageMoney: [required,{
          validator: checkZero,
          trigger: ['blur', 'change'],
        }],
      packageName: [required],
      packageDesc: [required],
    });

    return {
      gridRef,
      ...toRefs(state),
      form,
      validateInfos,
      items: [
        { key: 'packageName', label: '套餐名称' },
        { key: 'packageDesc', label: '套餐描述' },
        { key: 'goldNumber', label: '金币数量' },
        { key: 'packageMoney', label: '金额' },
        { key: 'updateUser', label: '操作人' },
        { key: 'updateTime', label: '最后更新时间' },
        {
          key: 'enable',
          label: '启用状态',
          dataset: [
            { value: '0', label: '启用' },
            { value: '1', label: '禁用' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: '',
          title: '序号',
          width: 80,
          ellipsis: true,
          align: 'center',
          customRender: ({ index }) => `${index + 1}`,
        },
        {
          dataIndex: 'packageName',
          title: '套餐名称',
          width: 140,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'packageCode',
          title: '套餐ID',
          width: 240,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'goldNumber',
          title: '金币数量',
          width: 100,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'packageMoney',
          title: '金额(元)',
          width: 100,
          ellipsis: true,
          align: 'center',
          slots: { customRender: 'packageMoney' },
        },
        {
          dataIndex: 'packageDesc',
          title: '套餐描述',
          width: 200,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'updateUser',
          title: '操作人',
          width: 100,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'updateTime',
          title: '最后操作时间',
          width: 180,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'enable',
          title: '启用状态',
          width: 140,
          slots: { customRender: 'enable' },
          ellipsis: true,
          align: 'center',
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      btnOperation: [
        {
          type: 'add',
          label: '',
          icon: 'PlusOutlined',
          permission: 'recharge:add',
          fnClick: () => {
            state.visible = true;
            resetFields();
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '修改',
          icon: 'EditTwoTone',
          permission: 'recharge:update',
          fnClick: record => {
            resetFields();
            state.visible = true;
            console.log('record', record)
            Object.assign(form, record);
            if (form.packageMoney != 0) {
              form.packageMoney = (record.packageMoney / 100).toFixed(2);
            }
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: 'DeleteTwoTone',
          permission: 'recharge:delete',
          fnClick: ({ id }) => {
            configurationApi
              .delete(`configuration:delete`, { ids: [id] })
              .then(() => {
                state.visible = false;
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });
          },
        },
      ],
      url: configurationApi.pageUrl,
      gridHeight: document.body.clientHeight,
      handleOk() {
        validate().then(() => {
          Modal.confirm({
            title: `套餐ID需要到苹果后台同步配置`,
            // 确定按钮颜色
            // okType: 'danger',
            onOk: () => {
              let type = 'add';
              form?.id && (type = 'update');
              let data = { ...toRaw(form) };
              data.packageMoney = data.packageMoney * 100;
              data.packageTitle = data.packageDesc;
              configurationApi[type](`configuration:${type}`, {
                packageType: state.search.type,
                ...toRaw(data),
              }).then(() => {
                state.visible = false;
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });

            },
          });

        });
      },
      handleClickSwitch(record) {
        configurationApi.updateStatus('', {
          id: record.id,
          enable: record.enable
        }).then((res) => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      }

    };
  },
};
</script>
  <style lang="less" scoped>
.flex-row {
  position: relative;
  .row-uni {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }
}
/deep/ .ant-table-body{
    max-height: 70vh !important;
  }
</style>